import { gql } from "@apollo/client";

/* FRAGMENTS */
export const APPLIANCE_DETAIL = gql`
    fragment appliance_detail on common_appliances {
        id
        name
        model
        manufacturer
        serialnumber
        realestateid
        unitid
    }
`;

export const UNIT_DETAIL = gql`
    fragment unit_detail on common_units {
        id
        number
        name
        houseid
    }
`;

export const REALESTATE_DETAIL = gql`
    fragment realestate_detail on common_realestates {
        id
        name
        number
        consolidation
        houses(limit: 1) {
            zip
            city
        }
        thumbnail: v_realestatefiles(
            where: { type: { _eq: 311 } }
            order_by: { filedate: desc, fileid: desc }
            distinct_on: fileid
            limit: 1
        ) {
            fileid
        }
    }
`;

export const REALESTATE_DETAIL_ALL_TICKETS = gql`
    fragment realestate_detail_all_tickets on common_realestates {
        id
        name
        number
        consolidation
        houses(limit: 1) {
            zip
            city
        }
    }
`;

export const INCIDENTPERSON_DETAIL = gql`
    fragment incidentpersons_detail on ticket_incidentpersons {
        person {
            id
            customerid
            name1
            name2
            mobile
            phoneprivate
            email
            gender
            origin
            addresses(order_by: { validfrom: desc_nulls_last }, limit: 1) {
                street
                housenumber
                zip
                city
            }
        }
        role
    }
`;

export const INCIDENTPERSON_DETAIL_ALL_TICKETS = gql`
    fragment incidentpersons_detail_all_tickets on ticket_incidentpersons {
        person {
            id
            name1
            name2
            mobile
            phoneprivate
            email
            gender
            origin
        }
        role
    }
`;

export const USER_DETAIL = gql`
    fragment incidentuser_detail on ums_users {
        id
        name1
        name2
    }
`;

export const ORDER_DETAIL_TICKET = gql`
    fragment order_detail_ticket on ticket_orders {
        number
        workflowinstance
        state
        contractortype
        id
        primarycontactperson {
            id
            name1
            name2
            email
            mobile
            phoneprivate
            phonebusiness
        }
        primarycontacttype
        service_provider: person {
            name1
            name2
        }
    }
`;

export const INCIDENT_HISTORY_DETAIL = gql`
    fragment incident_history_detail on ticket_v_incident_history {
        incidentid
        orderid
        type
        inserted
        history_username
        old_values
        new_values
        comment_username
        comment_text
        email_subject
        email_body
        email_type
        email_recipients
        email_sender
        inboundfiles: v_inboundfiles {
            id: fileid
            name
        }
        failed_outbound_email_recipients: outboundemailrecipients(
            where: { emaillogs: { event: { _nin: ["delivered", "processed", "open"] } } }
        ) {
            id
            emailaddress
            emaillogs(order_by: { timestamp: desc }) {
                id
                event
                message
            }
        }
    }
`;

export const INCIDENT_FILES_DETAIL = gql`
    fragment incidentfiles_detail on file_v_incidentfiles {
        incidentfileid: id
        fileid
        type
    }
`;

export const INCIDENT_DETAIL = gql`
    ${UNIT_DETAIL}
    ${REALESTATE_DETAIL}
    ${INCIDENTPERSON_DETAIL}
    ${APPLIANCE_DETAIL}
    ${USER_DETAIL}
    ${INCIDENT_FILES_DETAIL}
    ${ORDER_DETAIL_TICKET}
    ${INCIDENT_HISTORY_DETAIL}
    fragment incident_detail on ticket_incidents {
        id
        origin
        number
        date
        type
        realestateid
        houseid
        unitid
        tenantid
        tenancyid
        componentid
        roomtype
        appliancetype
        assigneduserid
        manufacturer
        serialnumber
        key
        nameplate
        state
        workflowinstance
        notificationid
        incidents_mls(where: { language: { _eq: $language } }) {
            title
            description
            altcomponent
        }
        realestate {
            ...realestate_detail
        }
        unit {
            ...unit_detail
        }
        incidentpersons {
            ...incidentpersons_detail
        }
        appliance {
            ...appliance_detail
        }
        user {
            ...incidentuser_detail
        }
        incidentfiles: v_incidentfiles {
            ...incidentfiles_detail
        }
        orders(order_by: { number: asc }, where: { state: { _neq: 99 } }) {
            ...order_detail_ticket
        }
        history(order_by: { inserted: desc }) {
            ...incident_history_detail
        }
        notification {
            originalmessage
        }
    }
`;

export const INCIDENT_DETAIL_ALL_TICKETS = gql`
    ${REALESTATE_DETAIL_ALL_TICKETS}
    ${APPLIANCE_DETAIL}
    ${INCIDENTPERSON_DETAIL_ALL_TICKETS}
    ${USER_DETAIL}
    fragment incident_detail_all_tickets on ticket_incidents {
        id
        origin
        number
        date
        type
        roomtype
        appliancetype
        manufacturer
        serialnumber
        key
        nameplate
        state
        workflowinstance
        incidents_mls(where: { language: { _eq: $language } }) {
            title
            description
            altcomponent
        }
        incidentpersons(where: { role: { _eq: 30 } }) {
            ...incidentpersons_detail_all_tickets
        }
        realestate {
            ...realestate_detail_all_tickets
        }
        appliance {
            ...appliance_detail
        }
        user {
            ...incidentuser_detail
        }
    }
`;

/* TODO: replace user on incident_detail fragment with User-Fragment: Issue #7171
user {
    ...incidentuser_detail
}
*/

export const TENANCY_PERSON_DETAIL = gql`
    fragment tenancy_person_detail on ticket_v_tenancypersons {
        customerid
        realestateid
        unitid
        personid
        name1
        name2
        mobile
        phoneprivate
        email
        gender
        origin
        addresses(order_by: { validfrom: desc_nulls_last }, limit: 1) {
            street
            housenumber
            zip
            city
        }
    }
`;

/* INTERFACES */
export interface ConnectionExtraData {
    __typename: "extradata";
    accountid: string | null;
    apiscope: string | null;
    frontend: string | null;
}

/* QUERIES */
export const GET_TICKET_BY_NUMBER = gql`
    ${INCIDENT_DETAIL}
    query GetTicketByNumber($ticketNumber: Int, $language: String = "de") {
        ticket_incidents(where: { number: { _eq: $ticketNumber } }, limit: 1) {
            ...incident_detail
        }
        primarycontacttypeEnum: common_enumerations(where: { type: { _eq: 559 }, language: { _eq: $language } }) {
            id
            key
            label
        }
    }
`;

export const GET_PROCESSES = gql`
    query GetProcesses($language: String = "de") {
        processes: ec_processes(order_by: { number: asc }) {
            notificationtype
            process_mls(where: { language: { _eq: $language } }) {
                title
            }
        }
    }
`;

export const GET_TICKET_ENUMERATIONS = gql`
    query GetTicketEnumerations($language: String = "de") {
        roomtypes: common_enumerations(where: { type: { _eq: 503 }, language: { _eq: $language } }) {
            key
            label
        }
        appliancetypes: common_enumerations(where: { type: { _eq: 504 }, language: { _eq: $language } }) {
            key
            label
        }
    }
`;

export const GET_REALESTATE_DATA = gql`
    ${REALESTATE_DETAIL}
    query GetRealestateData {
        realestates: common_realestates(order_by: { name: asc }) {
            ...realestate_detail
        }
    }
`;

export const GET_REALESTATEUSER_AND_ACCESS = gql`
    query GetRealestateUserAndAccessData {
        realestateUsersAndAccess: ticket_tickets_by_realestate_user {
            id
            realestateid
            userid
            role
        }
    }
`;

export const GET_UNIT_DATA_BY_REALESTATEID = gql`
    ${UNIT_DETAIL}
    query GetUnitDataByRealestateId($realestateid: uuid!) {
        houses: common_houses(where: { realestateid: { _eq: $realestateid } }) {
            id
            units {
                ...unit_detail
            }
        }
    }
`;

export const GET_INCIDENT_RELATIONSHIP_IDS = gql`
    query GetIncidentReleationshipIds($incidentid: uuid!) {
        incident_mls: ticket_incidents_mls(where: { incidentid: { _eq: $incidentid } }) {
            id
        }
        incident_contactperson: ticket_incidentpersons(where: { incidentid: { _eq: $incidentid }, role: { _eq: 30 } }) {
            id
        }
        incident_files: file_v_incidentfiles(where: { incidentid: { _eq: $incidentid }, role: { _eq: 30 } }) {
            incidentfileid: id
            fileid
            type
        }
    }
`;

export const GET_TENANCY_PERSONS_WITH_QUERY = gql`
    ${TENANCY_PERSON_DETAIL}
    query GetTenancyPersonsWithQuery($searchQuery: String) {
        tenancy_persons: ticket_v_tenancypersons(
            where: {
                _or: [
                    { name1: { _ilike: $searchQuery } }
                    { name2: { _ilike: $searchQuery } }
                    { mobile: { _ilike: $searchQuery } }
                    { email: { _ilike: $searchQuery } }
                    { addresses: { city: { _ilike: $searchQuery } } }
                    { addresses: { zip: { _ilike: $searchQuery } } }
                    { addresses: { street: { _ilike: $searchQuery } } }
                ]
            }
        ) {
            ...tenancy_person_detail
        }
    }
`;

export const GET_TENANCY_PERSONS_BY_REALESTATEID = gql`
    ${TENANCY_PERSON_DETAIL}
    query GetTenancyPersonsByRealestateId($realestateId: uuid!) {
        tenancy_persons: ticket_v_tenancypersons(where: { realestateid: { _eq: $realestateId } }) {
            ...tenancy_person_detail
        }
    }
`;

export const GET_TENANCY_INFORMATION = gql`
    query GetTenancyInformation($unitid: uuid!) {
        tenancys: common_tenancys(
            where: {
                unitid: { _eq: $unitid }
                _and: { _or: [{ leaseend: { _gt: "now()" } }, { leaseend: { _is_null: true } }] }
            }
            order_by: { leasestart: desc }
            limit: 1
        ) {
            id
            tenantid
        }
    }
`;

export const GET_APPLIANCE_DATA = gql`
    ${APPLIANCE_DETAIL}
    query GetApplianceData {
        appliances: common_appliances(order_by: { name: asc }) {
            ...appliance_detail
        }
    }
`;

export const GET_ALL_TICKETS = gql`
    ${INCIDENT_DETAIL_ALL_TICKETS}
    query GetAllTicketsParameters($language: String = "de", $limit: Int = 99999, $offset: Int = 0) {
        ticket_incidents(order_by: { number: desc }, limit: $limit, offset: $offset, where: { state: { _neq: 99 } }) {
            ...incident_detail_all_tickets
        }
    }
`;

export const GET_INCIDENTPERSON_ENUM = gql`
    query GetIncidentPersonTypes($language: String = "de") {
        common_enumerations(where: { type: { _eq: 552 }, language: { _eq: $language } }) {
            key
            label
        }
    }
`;

export const GET_COMPONENT_DATA = gql`
    query GetComponentData($language: String = "de") {
        components: ec_components(order_by: { sort: asc }) {
            id
            parentid
            type
            parameters
            component_mls(where: { language: { _eq: $language } }) {
                title
            }
            child_components: components(order_by: { sort: asc }) {
                id
            }
        }
    }
`;

export const GET_SERVICE_PERSON_DATA = gql`
    query GetServicePersonData {
        service_persons: common_v_realestateperson_details_manager(where: { role: { _in: [12, 20] } }) {
            id
            realestateid
            personid
            name1
            name2
            email
            mobile
            phonebusiness
            phoneprivate
        }
    }
`;

export const GET_BILLING_DATA = gql`
    query GetBillingData {
        billingemail: ums_customersettings(where: { key: { _eq: "TICKET.BILLINGEMAIL" } }) {
            id
            value
        }
        billingaddress: ums_customersettings(where: { key: { _eq: "TICKET.BILLINGADDRESS" } }) {
            id
            value
        }
    }
`;

export const GET_USERS_TO_ASSIGN_TICKET = gql`
    query GetUsersToAssignTicket {
        ums_v_customer_users(
            where: {
                activated: { _eq: true }
                system: { _eq: false }
                blocked: { _eq: false }
                roles: { role: { _eq: 30 } }
            }
        ) {
            userid
            name
        }
    }
`;

export const GET_ORDERS_OF_TICKET = gql`
    ${ORDER_DETAIL_TICKET}
    query GetOrdersOfTicket($ticketId: uuid) {
        ticket_orders(where: { incidentid: { _eq: $ticketId } }, order_by: { number: asc }) {
            ...order_detail_ticket
        }
    }
`;

export const GET_INCIDENT_PERSONS_OF_TICKET = gql`
    ${INCIDENTPERSON_DETAIL}
    query GetIncidentPersonsOfTicket($ticketId: uuid) {
        ticket_incidentpersons(where: { incidentid: { _eq: $ticketId } }) {
            ...incidentpersons_detail
        }
    }
`;

export const GET_HISTORY_OF_TICKET = gql`
    ${INCIDENT_HISTORY_DETAIL}
    query GetHistoryOfTicket($ticketId: uuid) {
        ticket_v_incident_history(where: { incidentid: { _eq: $ticketId } }, order_by: { inserted: desc }) {
            ...incident_history_detail
        }
    }
`;

/* SUBSCRIPTIONS */

export const SUBCRIPTION_GET_ORDER_STATE = gql`
    subscription GetOrderState($id: uuid!) {
        ticket_orders(where: { id: { _eq: $id } }) {
            state
        }
    }
`;

export const SUBSCRIBE_TO_ASSIGNED_USER_UPDATES = gql`
    subscription SubscribeToAssignedUserUpdates($incidentid: uuid!) {
        incident: ticket_incidents(where: { id: { _eq: $incidentid } }) {
            user {
                id
                name1
                name2
            }
        }
    }
`;

export const SUBSCRIBE_TO_INCIDENT_PERSON_UPDATES = gql`
    ${INCIDENTPERSON_DETAIL}
    subscription SubscribeToIncidentPersonUpdates($incidentid: uuid!) {
        ticket_incidentpersons(where: { incidentid: { _eq: $incidentid } }) {
            ...incidentpersons_detail
        }
    }
`;

/*
TODO: Add realestateusers for filtering to GetAllTickets Issue #7171
realestateusers: common_realestateusers {
    realestateid
}
*/

/* MUTATIONS */
export const UPSERT_INCIDENT = gql`
    mutation UpsertIncident($incident: ticket_incidents_insert_input!) {
        upserted_incidents_data: insert_ticket_incidents_one(
            object: $incident
            on_conflict: {
                constraint: PK_incidents
                update_columns: [
                    origin
                    type
                    date
                    realestateid
                    houseid
                    unitid
                    tenantid
                    tenancyid
                    notificationid
                    roomtype
                    appliancetype
                    componentid
                    applianceid
                    manufacturer
                    serialnumber
                    key
                    nameplate
                ]
            }
        ) {
            id
            number
            date
            realestateid
            houseid
            unitid
            incidents_mls {
                id
                title
                description
                altcomponent
            }
            incidentpersons(where: { role: { _eq: 30 } }, limit: 1) {
                id
            }
        }
    }
`;

export const DELETE_FILE = gql`
    mutation DeleteFile($fileid: uuid!) {
        deleted_files: delete_file_files(where: { id: { _eq: $fileid } }) {
            affected_rows
        }
    }
`;

export const UPSERT_INCIDENT_COMMENT = gql`
    mutation UpsertIncidentComment($comment: ticket_incidentcomments_insert_input!) {
        insert_ticket_incidentcomments_one(
            object: $comment
            on_conflict: { constraint: PK_incidentcomments, update_columns: [language, text] }
        ) {
            id
        }
    }
`;

export const UPDATE_PERSON_DETAILS = gql`
    mutation UpdatePersonDetails($customerid: uuid!, $id: uuid!, $personDetails: common_persons_set_input!) {
        update_common_persons(where: { customerid: { _eq: $customerid }, id: { _eq: $id } }, _set: $personDetails) {
            returning {
                id
            }
        }
    }
`;
